<template>
  <v-card>
    <v-card-title>Категории</v-card-title>
    <v-card-text>
      <v-combobox
        v-model="selectedCats"
        :items="cats"
        item-text="name"
        item-value="id"
        outlined
        chips
        multiple
        hide-selected
      >
        <template v-slot:selection="{ attrs, item, parent, selected }">
          <v-chip
            v-if="item === Object(item)"
            v-bind="attrs"
            :input-value="selected"
            label
          >
          <span class="pr-2">
            {{ item.name }}
          </span>
            <v-icon
              small
              @click="deleteCat(item)"
            >
              $delete
            </v-icon>
          </v-chip>
        </template>
      </v-combobox>
    </v-card-text>
  </v-card>
</template>

<script>
import _ from 'lodash'
import qs from 'qs'
import httpClient from '@/services/http.service'

export default {
  name: 'ProductCats',
  props:{
    initProduct:{
      type: Object,
      default() {
        return {}
      }
    }
  },
  data(){
    return{
      product: this.initProduct,
      selectedCats: [],
      merged: []
    }
  },
  mounted() {
    const selectedCats = this.mergeCats()
    _.each(selectedCats, cat =>{
      this.selectedCats.push(this.cats.find(x=>x.id===cat))
    })
  },
  computed:{
    cats(){
      return JSON.parse(JSON.stringify(this.$store.state.productCats))
    }
  },
  watch:{
    selectedCats(val, prev){
      if (val.length === prev.length) return
      this.selectedCats = val.map(v => {
        if (typeof v === 'string') {
          this.createCat(v)
        }
        return v
      })
      this.debUpdateCat()
    }
  },
  methods:{
    mergeCats(){
      let cats = []
      _.each(this.product.products, product =>{
        cats = _.union(cats, product.cats)
      })
      this.merged = cats
      return cats.map(x=>Number(x)) //yii добавляет кавычки к ID, уберём их
    },
    deleteCat(item){
      console.log('окей, нужно удалить из cats вот эту хуйню: ', item)
      this.selectedCats.splice(this.selectedCats.findIndex(x=>x.id===item.id), 1)
      this.debUpdateCat()
      // httpClient.post('products/delete-cat', qs.stringify({url: this.product.products[0].ali_link, cat_id: item.id}))
    },
    createCat(name){
      httpClient.post('product-cats', qs.stringify({created_by: this.$store.state.user.id, name})).then(({data})=>{
        this.$store.dispatch('getProductCats', true)
        this.selectedCats.push(data)
      })
    },
    debUpdateCat:_.debounce(function () {
      this.updateCat()
    }, 2000),
    updateCat(){
      const cats = []
      _.each(this.selectedCats, cat =>{
        cats.push(cat.id)
      })
      httpClient.post('products/update-cat', qs.stringify({url: this.product.products[0].ali_link, cats}))
    }
  }
}
</script>

<style scoped>

</style>
