<template>
  <section id="showProduct">
    <v-row>
      <v-col cols="6">
        <v-card>
          <v-card-title>Шаблон: <strong v-if="product.hasOwnProperty('products')">{{product.products[0].template_title}}</strong></v-card-title>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">
                    Название шаблона
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr
                  v-for="(leProduct, i) in product.products"
                  :key="i"
                >
                  <td>{{ leProduct.template_title }}</td>
<!--                  <td>{{ item.calories }}</td>-->
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="product">
      <v-col cols="6">
        <show-posts :strict-url="false" :url="product.products[0].ali_link" :product_id="Number(product_id)" :channel_id="0" full-view></show-posts>
      </v-col>
      <v-col cols="6">
        <v-row>
          <v-col cols="12">
            <product-cats :init-product="product" :ali-link="product.products[0].ali_link"></product-cats>
          </v-col>
          <v-col cols="12">
            <product-tags :init-product="product" :ali-link="product.products[0].ali_link"></product-tags>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import _ from 'lodash'
import httpClient from '@/services/http.service'
import ShowPosts from '@/components/products/ShowPosts'
import ProductCats from '@/components/products/ProductCats'
import ProductTags from '@/components/products/ProductTags'

export default {
  name: 'ShowProduct',
  components:{
    ShowPosts, ProductCats, ProductTags
  },
  data(){
    return{
      product: false,
      product_id: this.$route.params.id,
      currentTab: 0,
      // dialog: false,
    }
  },

  created() {
    httpClient.get(`products/info?product_id=${this.product_id}`).then(({data})=>{
      this.product = data
    })
  },
  methods:{
    selectTemplate(template){
      this.$emit('selectTemplate', template)
      this.dialog = false
    },
  }
}
</script>

<style scoped>

</style>
