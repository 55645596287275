<template>
  <v-card>
    <v-card-title>
      Теги
    </v-card-title>
    <v-card-text>
      <v-combobox
      v-model="selectedTags"
      :items="tags"
      item-value="id"
      item-text="name"
      multiple
      chips
      outlined
      hide-selected
      >
        <template v-slot:selection="{ attrs, item, parent, selected }">
          <v-chip
            v-if="item === Object(item)"
            v-bind="attrs"
            :input-value="selected"
            label
          >
          <span class="pr-2">
            {{ item.name }}
          </span>
            <v-icon
              small
              @click="deleteTag(item)"
            >
              $delete
            </v-icon>
          </v-chip>
        </template>
      </v-combobox>
    </v-card-text>
  </v-card>
</template>

<script>
import qs from 'qs'
import _ from 'lodash'
import httpClient from '@/services/http.service'

export default {
  name: 'ProductTags',
  props:{
    initProduct:{
      type: Object,
      default: () => {}
    },
    aliLink:{
      type: String,
      default: ''
    }
  },
  data(){
    return{
      product: this.initProduct,
      selectedTags: [],
    }
  },
  created() {
    const selectedTags = this.mergeTags()
    _.each(selectedTags, tag =>{
      this.selectedTags.push(this.tags.find(x=>x.id===tag))
    })
  },
  computed:{
    tags(){
      return JSON.parse(JSON.stringify(this.$store.state.productTags))
    }
  },
  watch:{
    selectedTags(val, prev){
      if (val.length === prev.length) return
      this.selectedTags = val.map(v => {
        if (typeof v === 'string') {
          this.createTag(v)
        }
        return v
      })
      this.debUpdateTag()
    }
  },
  methods:{
    mergeTags(){
      let tags = []
      _.each(this.product.products, product =>{
        tags = _.union(tags, product.tags)
      })
      this.merged = tags
      return tags.map(x=>Number(x)) //yii добавляет кавычки к ID, уберём их
    },
    deleteTag(item){
      this.selectedTags.splice(this.selectedTags.findIndex(x=>x.id===item.id), 1)
      this.debUpdateTag()
    },
    createTag(item){
      httpClient.post('product-tags', qs.stringify({name: item})).then(({data})=>{
        this.$store.dispatch('getProductTags')
        this.selectedTags.push(data)
      })
    },
    debUpdateTag:_.debounce(function () {
      this.updateTag()
    }, 2000),
    updateTag(){
      const tags = []
      _.each(this.selectedTags, tag =>{
        tags.push(tag.id)
      })
      httpClient.post(`products/update-tag?ali_link=${this.aliLink}`, qs.stringify({tags}))
    }
  }
}
</script>

<style scoped>

</style>
